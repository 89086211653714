import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="theme"
export default class extends Controller {
  // Add class for Posthog delayed survey
  connect() {
    setTimeout(() => {
      document.body.classList.add("delayed-survey");
    }, 60000); // 60000 milliseconds = 60 seconds
  }

  toggleDarkModeClass() {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  toggle() {
    if (localStorage.theme === 'dark') {
      localStorage.theme = 'light'
    } else {
      localStorage.theme = 'dark'
    }
    this.toggleDarkModeClass()
  }
}
