import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="closable"
export default class extends Controller {

  close() {
    this.element.remove(); // Or `this.element.style.display = 'none';` to just hide it
    // Assuming you want to keep the cookie functionality to remember the closed state across sessions/pages
    this.setCookie('element_closed', 'true', 7); // Change 'element_closed' to a more specific name if needed
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
}
