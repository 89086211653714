import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper/bundle';

// Connects to data-controller="swiper"
export default class extends Controller {
  connect() {
    // Check if the autoload parameter is true
    const autoload = this.element.closest('[data-autoload]').dataset.autoload === 'true';

    const swiperConfig = {
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    // Add autoplay settings if autoload is true
    if (autoload) {
      swiperConfig.speed = 1000;
      swiperConfig.autoplay = {
        delay: 3500,
        disableOnInteraction: false,
      };
    }

    // Initialize Swiper
    this.swiper = new Swiper('.swiper-container', swiperConfig);
  }
}
