import posthog from 'posthog-js'

if (getEnv() !== "development") {
  try {
    posthog.init(getMetaValue("posthog"), { api_host: 'https://eu.posthog.com' })
  } catch (error) {
    console.log("Unable to start Posthog");
  }
}

function getEnv() {
  const env = JSON.parse(document.body.getAttribute('data-constants')).env;
  return env;
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute("content");
}
