import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="category-filter"
export default class extends Controller {
  submit(e) {
    e.preventDefault(); // Prevent the default form submission

    const form = this.element;
    const formData = new FormData(form);

    const params = new URLSearchParams(formData);
    const newUrl = `${form.action}?${params.toString()}`;

    // Define the frames you want to update
    const framesToUpdate = ["category_results", 'mobile_product_counter'];

    // Trigger Turbo.visit for each frame
    framesToUpdate.forEach(frameId => {
      Turbo.visit(newUrl, { frame: frameId });
    });

    history.pushState({}, "", newUrl);
  }
}
