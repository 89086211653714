import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-spy"
export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.observer = new IntersectionObserver(entries => this.handleIntersect(entries), {
      threshold: 0.1 // Adjust based on your needs
    });
    this.observeSections();
  }

  observeSections() {
    this.linkTargets.forEach(link => {
      const selector = link.getAttribute('href');
      const section = document.querySelector(selector);
      if (section) {
        this.observer.observe(section);
      }
    });
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.linkTargets.forEach(link => link.classList.remove('active')); // Remove 'active' from all links
        const activeLink = this.linkTargets.find(link => link.getAttribute('href') === '#' + entry.target.id);
        if (activeLink) {
          activeLink.classList.add('active'); // Add 'active' to the current link
        }
      }
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
