import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-more"
export default class extends Controller {
  static targets = ["toggleable", "toggleButton"];

  toggle() {
    this.toggleableTargets.forEach(element => {
      element.classList.toggle('expanded'); // Or 'hidden', depending on your CSS
    });

    this.updateButtonText();
  }

  updateButtonText() {
    const isExpanded = this.toggleableTargets.some(element => element.classList.contains('expanded'));
    this.toggleButtonTarget.textContent = isExpanded ? "Show Less ↑" : "Show More ↓";
  }
}
