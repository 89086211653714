import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["logo", "screenshots", "logoCount", "screenshotsCount"]

  updateUploadCount(event) {
    let files = event.currentTarget.files;
    let count = files.length;
    let label = `${count} file${count !== 1 ? 's' : ''} selected`;

    if (event.currentTarget.matches("[data-upload-target='logo']")) {
      this.logoCountTarget.textContent = label;
    } else if (event.currentTarget.matches("[data-upload-target='screenshots']")) {
      this.screenshotsCountTarget.textContent = label;
    }
  }
}
