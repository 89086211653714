import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fade-out"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.add('opacity-0');
      this.element.addEventListener('transitionend', () => {
        this.element.remove(); // This will completely remove the element from the DOM
      });
    }, 4000); // Adjust the time as needed
  }
}
