// import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   static targets = ['container', 'input', 'categoryContainer', 'productContainer', 'noResults', 'overlay'];

//   // connect() {
//   //   this.inputTarget.addEventListener('focus', this.showOverlay.bind(this));
//   //   this.inputTarget.addEventListener('blur', this.hideOverlay.bind(this));
//   //   this.handleClickedOutside();
//   // }

//   // disconnect() {
//   //   this.inputTarget.removeEventListener('focus', this.showOverlay.bind(this));
//   //   this.inputTarget.removeEventListener('blur', this.hideOverlay.bind(this));
//   // }

//   submit() {
//     const query = this.inputTarget.value;
//     if (query.length < 3) {
//       this.hideElement(this.containerTarget);
//       return;
//     }
//     this.performSearch('Product', query);
//     this.performSearch('Category', query);
//   }


//   async performSearch(index, query) {
//     const searchUrl = `/search/${index.toLowerCase()}?query=${encodeURIComponent(query)}`;
//     this.searching();
//     this.displayNoResults();
//     try {
//       const response = await fetch(searchUrl);
//       const content = await response.json();
//       if (content.hits && content.hits.length > 0) {
//         this.showElement(this.containerTarget);
//         this.undisplayNoResults();
//         this.displayResults(index, content.hits);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//     this.searching_done();
//   }

//   displayResults(index, hits) {
//     const containerTarget = this[`${index.toLowerCase()}ContainerTarget`];
//     this.showElement(containerTarget);
//     const list = containerTarget.querySelector("ul");
//     list.innerHTML = '';
//     hits.forEach((hit) => {
//       if (index === 'Product') {
//         const dealEnv = this.productContainerTarget.dataset.dealEnv;
//         const dealAvailable = hit.deal_availability_tags.includes(dealEnv);
//         if (!dealAvailable && dealEnv !== "marketplace") return;
//       }
//       list.append(this.buildListItem(hit, index === 'Category', index === 'Product'));
//     });
//     if (list.children.length === 0 && index === 'Product') {
//       this.hideElement(this.productContainerTarget);
//     }
//   }

//   displayNoResults() {
//     this.showElement(this.containerTarget);
//     this.showElement(this.noResultsTarget);
//     this.hideElement(this.categoryContainerTarget);
//     this.hideElement(this.productContainerTarget);
//   }

//   undisplayNoResults() { this.hideElement(this.noResultsTarget); }

//   buildListItem(hit, translate = false, logo = false) {
//     const item = document.createElement('li');
//     const link = document.createElement('a');
//     const locale = this.element.dataset.locale;
//     const name = translate ? hit.name_i18n[locale] : hit.name;
//     link.href = `/${locale}${hit.path}`;
//     item.classList.add('py-1', 'px-4', 'hover:bg-cloud-darker');

//     item.innerHTML = logo ? this.buildLogoElement(hit, name, locale) : this.buildTextElement(name);
//     link.append(item);
//     return link;
//   }

//   buildLogoElement(hit, name, locale) {
//     const src = hit.logo.url;
//     const tagline = hit.tagline_i18n[locale];
//     const dealEnv = this.productContainerTarget.dataset.dealEnv;
//     const dealAvailable = hit.deal_availability_tags.includes(dealEnv);
//     const dealTagContent = this.productContainerTarget.dataset.dealAvailable;
//     const dealTag = dealAvailable ? `<p class="bg-mint-light text-mint px-1.5 rounded-md text-xxs font-medium w-fit">${dealTagContent}</p>` : '';
//     return `<div class="flex justify-between items-center">
//               <div class="flex">
//                 <div class="flex flex-col justify-center mr-2 min-w-8">
//                   <img alt="${name} logo" height="32" width="32" class="rounded-sm border border-cloud p-1 bg-white h-8 object-contain" loading="lazy" src="${src}">
//                 </div>
//                 <div class="pt-0.5">
//                   <p class="font-medium">${name}</p>
//                   <p>${tagline}</p>
//                 </div>
//               </div>
//               <div class="min-w-1/5 flex justify-end">
//                 ${dealTag}
//               </div>
//             </div>`;
//   }

//   buildTextElement(name) {
//     return name;
//   }

//   searching() {
//     this.inputTarget.classList.add('animate-pulse');
//     this.inputTarget.style.color = '#f8d2b7';
//   }

//   searching_done() {
//     this.inputTarget.classList.remove('animate-pulse');
//     this.inputTarget.style.color = null;
//   }

//   hideElement(element) {
//     element.classList.add('hidden');
//   }

//   showElement(element) {
//     element.classList.remove('hidden');
//   }

//   // handleClickedOutside() {
//   //   document.addEventListener('click', (event) => {
//   //     if (!this.element.contains(event.target)) {
//   //       this.hideElement(this.containerTarget);
//   //     }
//   //   });
//   // }

//   // showOverlay() {
//   //   this.overlayTarget.style.display = 'block';
//   //   this.inputTarget.classList.remove('bg-gray-900');
//   //   this.inputTarget.classList.add('bg-gray-700');
//   // }

//   // hideOverlay() {
//   //   setTimeout(() => {
//   //     this.hideElement(this.containerTarget);
//   //     this.overlayTarget.style.display = 'none';
//   //     this.inputTarget.classList.add('bg-gray-900');
//   //     this.inputTarget.classList.remove('bg-gray-700');
//   //   }, 200);
//   // }
// }
