import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, userLang: String }

  translate() {
    get(this.urlValue, {
      responseKind: "json",
      query: { lang: this.userLangValue },
      success: (response) => {
        this.element.innerHTML = response.body.translatedText;
      }
    })
  }
}
