import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="track-event"
export default class extends Controller {
  static values = {
    event: String,     // To capture the name of the event
    metadata: String   // Optional metadata as a JSON string
  }

  fireEvent(event) {
    event.preventDefault();
    const eventName = this.eventValue;
    const metaData = this.metadataValue ? JSON.parse(this.metadataValue) : {};

    sa_event(eventName, metaData); // Track the event with optional metadata

    // Navigate if it's a link, opening in a new window/tab
    if (this.element.tagName === 'A') {
      const url = this.element.getAttribute('href');
      window.open(url, '_blank'); // This opens the URL in a new tab
    }
  }
}
