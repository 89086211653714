import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "productList", "compareTray", "compareLink"]

  connect() {
    this.preventDefaultAction = this.preventDefaultAction.bind(this);
    this.updateCompareTray();
  }

  checkboxChanged() {
    this.updateCompareTray();
    this.manageCheckboxes();
  }

  updateCompareTray() {
    const selectedProducts = this.checkboxTargets.filter(cb => cb.checked);
    this.productListTarget.innerHTML = ''; // Clear previous selections

    selectedProducts.forEach((product) => {
      const name = product.dataset.name;
      const logo = product.dataset.logo;
      const detailHTML = `
          <div class="h-12 w-12 sm:w-16 sm:h-16 border-4 bg-white border-white shadow-md ring-1 ring-gray-200 rounded-md">
              <img src="${logo}" alt="${name} logo" class="w-full h-full rounded-md">
          </div>
          `;
      this.productListTarget.insertAdjacentHTML("beforeend", detailHTML);
    });

    this.compareTrayTarget.classList.toggle('hidden', selectedProducts.length === 0);

    if (selectedProducts.length < 2) {
      this.compareLinkTarget.classList.add('opacity-50', 'cursor-not-allowed');
      this.compareLinkTarget.addEventListener('click', this.preventDefaultAction);
    } else {
      this.compareLinkTarget.classList.remove('opacity-50', 'cursor-not-allowed');
      this.compareLinkTarget.removeEventListener('click', this.preventDefaultAction);
    }

    const slugs = selectedProducts.map(cb => cb.value).join('-vs-');
    this.compareLinkTarget.href = `/compare/${slugs}`;
  }

  preventDefaultAction(event) {
    event.preventDefault();
  }

  manageCheckboxes() {
    const selectedProducts = this.checkboxTargets.filter(cb => cb.checked).length;

    if (selectedProducts >= 4) {
      this.checkboxTargets.forEach((checkbox) => {
        if (!checkbox.checked) {
          checkbox.disabled = true;
        }
      });
    } else {
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  }

  removeAll() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.updateCompareTray(); // Update the modal to reflect the changes
    this.manageCheckboxes(); // Ensure checkboxes are correctly enabled/disabled
  }
}
