import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dialog"];

  open() {
    this.dialogTarget.showModal();
    this.disableScrolling();
  }

  close() {
    this.dialogTarget.close();
    this.enableScrolling();
  }

  clickOutside(event) {
    if (event.target === this.dialogTarget) {
      this.close();
    }
  }

  disableScrolling() {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  }

  enableScrolling() {
    document.documentElement.style.overflow = '';
    document.body.style.overflow = '';
  }
}
