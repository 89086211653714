import DropdownController from './dropdown_controller'

// Connects to data-controller="rotating-dropdown"
export default class extends DropdownController {
  static targets = [...DropdownController.targets, "chevron"]

  connect() {
    super.connect()
    this.rotateChevron()
  }

  toggle() {
    super.toggle()
    this.rotateChevron()
  }

  show() {
    super.show()
    this.rotateChevron()
  }

  hide() {
    super.hide()
    this.rotateChevron()
  }

  rotateChevron() {
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transform = this.openValue ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }
}
