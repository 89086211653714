import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="external-link"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('a[href^="http://"], a[href^="https://"]').forEach(link => {
      if (link.href.indexOf(window.location.hostname) === -1) {
        link.target = "_blank";
        link.rel = "noopener noreferrer";
      }
    });
  }
}
