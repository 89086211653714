import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.style.display = 'none';
    }, 4000); // <-- time in milliseconds, 4000 == 4 seconds
  }
}
